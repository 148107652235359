import React from 'react';
import cn from 'classnames';

import successIcon from '@images/icons/success.svg';
import copyIcon from '@images/icons/copy.svg';

import type { PromosArgs } from '@customTypes/product';

import styles from './Promos.module.scss';

export function Promos({ promos }: PromosArgs) {
  const [isCopySuccess, setIsCopySuccess] = React.useState(false);

  const copyToClipboard = (text: string) => () => {
    setIsCopySuccess(true);
    setTimeout(() => setIsCopySuccess(false), 1000);
    return navigator.clipboard.writeText(text);
  };

  return (
    <div className={styles.promos}>
      {promos.map((promo, index) => (
        <div
          className={cn({ [styles.promo]: true, [styles.promoMargin]: index > 1 })}
          key={`promo-${promo.id}`}
        >
          <div
            className={cn({
              [styles.title]: true,
              [styles.blue]: promo.type === 'promo',
            })}
            dangerouslySetInnerHTML={{ __html: promo.title }}
          />

          {promo.promocode !== ' ' && (
            <>
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: 'Промокод' }}
              />
              <div
                className={cn({
                  [styles.copy]: true,
                  [styles.copySuccess]: isCopySuccess,
                })}
              >
                <img src={successIcon} alt="success icon" />
                <p>Промокод скопирован</p>
              </div>
              <button
                type="button"
                onClick={copyToClipboard(promo.promocode)}
                className={styles.promocode}
              >
                {promo.promocode}
                <img src={copyIcon} alt="copy icon" />
              </button>
            </>
          )}
        </div>
      ))}
    </div>
  );
}
