import { useStaticQuery, graphql } from 'gatsby';

export const useMiniServices = () => {
  const {
    allStrapiMiniService: { nodes },
  } = useStaticQuery(graphql`
    {
      allStrapiMiniService {
        nodes {
          id
          title
          subtitle
          miniSubtitle
          color
          url
          price
          icon {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `);
  return nodes || [];
};
