import React from 'react';
import Slider from 'react-slick';
import cn from 'classnames';

import { GallerySlide } from './components/GallerySlide';

import styles from './Gallery.module.scss';

interface GalleryArgs {
  photos: any[];
  productTitle?: string;
  label?: string;
  slickSettings?: any;
  video?: any;
}

const customPaging = () => <div className={styles.dot} />;

const appendDots = (dots: any) => (
  <div className={styles.navigation}>
    <ul className={styles.dots}>
      {dots.map((dot: any) => (
        <li
          key={dot.key}
          className={cn({
            [styles.dot]: true,
            [styles.dotActive]: dot.props.className === 'slick-active',
          })}
        >
          {dot.props.children}
        </li>
      ))}
    </ul>
  </div>
);

const sliderSettings = {
  dots: true,
  infinite: true,
  className: styles.slider,
  speed: 500,
  customPaging,
  appendDots,
  dotsClass: styles.navigation,
  slidesToShow: 1,
  slidesToScroll: 1,
  // eslint-disable-next-line react/jsx-no-useless-fragment
  prevArrow: <></>,
  // eslint-disable-next-line react/jsx-no-useless-fragment
  nextArrow: <></>,
};

export function Gallery({
  photos,
  productTitle = '',
  label = '',
  slickSettings = {},
  video = null,
}: GalleryArgs) {
  const options = {
    ...sliderSettings,
    ...slickSettings,
  };

  const normalizedGallery = [video, ...photos.slice(1)];
  return (
    <div className={styles.gallery}>
      <div className={styles.mobile}>
        <Slider
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...options}
        >
          {video
            ? normalizedGallery.map((image) => (
                <GallerySlide
                  image={image}
                  label={label}
                  productTitle={productTitle}
                  key={`slider-${image.localFile?.childImageSharp?.gatsbyImageData}-${image.id}`}
                />
              ))
            : photos.map((image) => (
                <GallerySlide
                  image={image}
                  label={label}
                  productTitle={productTitle}
                  key={`slider-${image.localFile?.childImageSharp?.gatsbyImageData}-${image.id}`}
                />
              ))}
        </Slider>
      </div>
      <div
        className={cn({
          [styles.desktop]: true,
          [styles.lastLarge]: Math.floor(photos.length / 3) * 3 + 2 === photos.length,
        })}
      >
        {video
          ? normalizedGallery.map((image) => (
              <GallerySlide
                image={image}
                label={label}
                productTitle={productTitle}
                key={`slider-${image.localFile?.childImageSharp?.gatsbyImageData}-${image.id}`}
              />
            ))
          : photos.map((image) => (
              <GallerySlide
                image={image}
                label={label}
                productTitle={productTitle}
                key={`slider-${image.localFile?.childImageSharp?.gatsbyImageData}-${image.id}`}
              />
            ))}
      </div>
    </div>
  );
}

Gallery.Slide = GallerySlide;
