import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import cn from 'classnames';

import ClosePopupIcon from '@images/icons/popup/ClosePopup.svg';
import { Button } from '@components/Button';
import { ButtonColors } from '@customTypes/button';

import styles from './Modal.module.scss';

export enum ModalWidth {
  S = '480',
  M = '640',
  L = '800',
}

interface ModalArgs {
  trigger: React.ReactNode;
  content: React.ReactNode;
  title?: string;
  description?: string;
  onConfirm?: () => void;
  onDecline?: () => void;
  confirmTitle?: string;
  declineTitle?: string;
  width?: ModalWidth;
  isTitleAlignCenter?: boolean;
}

export function Modal({
  trigger,
  content,
  title,
  description,
  onConfirm,
  onDecline,
  confirmTitle = 'Хорошо',
  declineTitle = 'Отмена',
  width = ModalWidth.M,
  isTitleAlignCenter = false,
}: ModalArgs) {
  return (
    <Dialog.Root>
      <Dialog.Trigger className={styles.dialogTrigger}>{trigger}</Dialog.Trigger>
      <Dialog.Overlay className={styles.dialogOverlay} />
      <Dialog.Portal>
        <Dialog.Content
          className={cn(styles.dialog, styles[`width${width}`])}
        >
          <div className={styles.dialogHeaderContainer}>
            {title && (
              <Dialog.Title
                className={cn({
                  [styles.dialogTitle]: true,
                  [styles.dialogTitleAlignCenter]: isTitleAlignCenter,
                })}
              >
                {title}
              </Dialog.Title>
            )}
            {description && (
              <Dialog.Description className={styles.dialogDescription}>
                {description}
              </Dialog.Description>
            )}
          </div>
          <div
            className={cn({
              [styles.contentWrapper]: true,
              [styles.topPadding]: !title,
              [styles.bottomPadding]: !onConfirm && !onDecline,
            })}
          >
            <div className={styles.dialogContent}>{content}</div>
          </div>
          {(onConfirm || onDecline) && (
            <div className={styles.dialogControls}>
              {onDecline && (
                <Dialog.Close>
                  <Button onClick={onDecline} title={declineTitle} color={ButtonColors.gray} />
                </Dialog.Close>
              )}
              {onConfirm && (
                <Dialog.Close>
                  <Button onClick={onConfirm} title={confirmTitle} color={ButtonColors.blue} />
                </Dialog.Close>
              )}
            </div>
          )}
          <Dialog.Close asChild>
            <button type="button" className={styles.closeButtonContainer}>
              <div className={styles.closeButton}>
                <img src={ClosePopupIcon} alt="Закрыть" />
              </div>
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
