import React from 'react';
import { Recommendations } from './Recommendations';
import styles from './RecommendationsWithHeader.module.scss';

export function RecommendationsWithHeader({ items, children }) {
  return (
    <div className={styles.recommendations}>
      <div className={styles.wrapper}>
        <h2 className={styles.header}>{children}</h2>
      </div>
      <div className={styles.itemsWrapper}>
        <Recommendations items={items} />
      </div>
    </div>
  );
}
