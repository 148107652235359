import React from 'react';
import cn from 'classnames';

import type { OrderServiceButtonArgs } from '@customTypes/product';
import { currency } from '@utils/numbers';
import { RubleSign } from '@components/Blocks/RubleSign';
import { DEFAULT_DESIGN_PRICE } from '@consts/designConsts';

import styles from './OrderService.module.scss';

export function OrderServiceButton({
  realPrice,
  isProductUnavailable,
  onClick,
  buttonType,
  inPlate = false,
}: OrderServiceButtonArgs) {
  const handleClick = React.useCallback(() => {
    if (isProductUnavailable) {
      return;
    }

    onClick();
  }, [isProductUnavailable, onClick]);

  return (
    <button
      type="button"
      onClick={handleClick}
      className={cn({
        [styles.actionService]: true,
        [styles[`${buttonType}`]]: true,
        [styles.inPlate]: inPlate,
        [styles.actionBtnDisabled]: isProductUnavailable,
      })}
    >
      {buttonType === 'gift' || buttonType === 'onlyGift' ? (
        <>
          <p>Купить сертификат</p>
          {isProductUnavailable ? (
            <div className={styles.actionPrice}>Нет в наличии</div>
          ) : (
            <div className={styles.actionPrice}>
              {currency(realPrice)}&nbsp;
              <RubleSign />
            </div>
          )}
        </>
      ) : (
        <>
          <p>Соберём за вас</p>
          <div className={styles.actionPrice}>
            {
              // eslint-disable-next-line no-irregular-whitespace
              isProductUnavailable ? 'Нет в наличии' : `от ${currency(DEFAULT_DESIGN_PRICE)} ₽`
            }
          </div>
        </>
      )}
    </button>
  );
}
