import React from 'react';

import { aboutQualityText } from '@consts/goodsConsts';
import { Delivery } from '@components/Blocks/Info/Delivery';
import type { ProductInfoArgs } from '@customTypes/product';

import { ProductInfoBlock } from './components/ProductInfoBlock';

import styles from './ProductInfo.module.scss';

export function ProductInfo({ productDescription }: ProductInfoArgs) {
  const productInfoItems = [
    {
      id: 1,
      header: 'О продукте',
      description: <div dangerouslySetInnerHTML={{ __html: productDescription }} />,
    },
    {
      id: 2,
      header: 'Наш метод печати',
      description: <div dangerouslySetInnerHTML={{ __html: aboutQualityText }} />,
      isOpen: true,
    },
    {
      id: 3,
      header: 'Доставка',
      description: <Delivery />,
    },
  ];

  return (
    <div className={styles.info}>
      {productInfoItems.map((item) => (
        <ProductInfoBlock
          key={`productInfoBlock_${item.id}`}
          header={item.header}
          content={item.description}
          isOpen={item.isOpen}
        />
      ))}
    </div>
  );
}
