import React from 'react';
import { useLocation } from '@reach/router';
import { getSrc } from 'gatsby-plugin-image';
import { isFieldPresentInStrapi } from '@utils/isFieldPresentInStrapi';
import Layout from '@components/Layouts/Layout';
import { Gallery } from '@components/Product/Gallery';
import { Metadata } from '@components/Product/Metadata';
import SEO from '@components/Layouts/SEO';
import { YandexTableau } from '@components/Layouts/YandexTableau';
import { RecommendationsWithHeader } from '@components/Blocks/RecommendationsWithHeader';
import { SchemaOrgProduct } from '@components/Layouts/SchemaOrg/SchemaOrgProduct';
import { useProducts } from '@hooks/useProducts';

import { event } from '@analytics/gtm';

import styles from './product-page.module.scss';

export function Head({ pageContext }) {
  const { id } = pageContext;
  const location = useLocation();
  const { productByUrl: product } = useProducts(id);
  const imageUrl = getSrc(product?.image?.localFile);

  const absoluteUrl = `${location.origin}${imageUrl}`;

  return (
    <>
      <SEO
        title={isFieldPresentInStrapi(product.seoTitle) ? product.seoTitle : product.title}
        description={
          isFieldPresentInStrapi(product.seoDescription)
            ? product.seoDescription
            : product.description
        }
        image={absoluteUrl}
      />
      <YandexTableau />
      <SchemaOrgProduct
        title={product.title}
        description={product.description || ''}
        imageURL={imageUrl || null}
        minPrice={product.realPrice}
        maxPrice={product.realPrice}
      />
    </>
  );
}

function ProductPage({ pageContext }) {
  const { id } = pageContext;
  const { productByUrl: product } = useProducts(id);

  const gtmEvent = React.useMemo(
    () => ({
      ecommerce: {
        currencyCode: 'RUB',
        impressions: [
          {
            name: product.title,
            id: product.key,
            price: product.realPrice,
            brand: 'Периодика',
            category: product.googleProductCategory,
          },
        ],
      },
    }),
    [product.title, product.key, product.realPrice, product.googleProductCategory]
  );

  const onPurchaseClick = React.useCallback(() => {
    const purchaseEvent = {
      ecommerce: {
        click: {
          products: [
            {
              name: product.title,
              id: product.key,
              price: product.realPrice,
              brand: 'Периодика',
              category: product.googleProductCategory,
            },
          ],
        },
      },
    };
    event('productClick', purchaseEvent);
  }, [product.title, product.key, product.realPrice, product.googleProductCategory]);

  const galleryVideo = product.video
    ? {
        ...product.video,
        type: 'video',
        poster: product.gallery[0].localFile?.childImageSharp?.gatsbyImageData,
      }
    : null;

  React.useEffect(() => {
    event('impressions', gtmEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <div className={styles.product}>
        {product.gallery && product.gallery.length > 0 && (
          <div className={styles.gallery}>
            <Gallery
              photos={product.gallery}
              miniatures
              productTitle={product.title}
              video={galleryVideo}
            />
          </div>
        )}
        <Metadata product={product} onPurchaseClick={onPurchaseClick} />
      </div>
      <RecommendationsWithHeader items={product.recommendations || []}>
        Вам также может понравиться
      </RecommendationsWithHeader>
    </Layout>
  );
}
export default ProductPage;
