import React from 'react';
import cn from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';

import playIcon from '../../../../../images/icons/play.svg';
import pauseIcon from '../../../../../images/icons/pause.svg';
import loadingIcon from '../../../../../images/icons/loader.svg';

import styles from './GallerySlide.module.scss';

interface GallerySlideArgs {
  image: {
    localFile: any; // NOTE: Gatsby is weird with image typing, so it has to do
    poster?: any;
    type?: string;
  };
  productTitle: string;
  label?: string;
}

export function GallerySlide({ image, label, productTitle }: GallerySlideArgs) {
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const [isVideoPlaying, setIsVideoPlaying] = React.useState(true);
  const [isShowControls, setIsShowControls] = React.useState(false);

  const handlePauseVideo = () => {
    if (!videoRef.current) {
      return;
    }

    if (isVideoPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }

    setIsVideoPlaying(!isVideoPlaying);
  };

  const handleShowVideoControls = () => {
    setIsShowControls(!isShowControls);
  };

  React.useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch(() => {
        if (videoRef.current) {
          // videoRef.current.controls = true;
          setIsVideoPlaying(true);
        }
      });
    }
  }, []);

  return (
    <div className={styles.slide}>
      {image.type !== 'video' ? (
        <>
          <GatsbyImage
            image={image.localFile?.childImageSharp?.gatsbyImageData}
            alt={productTitle}
          />
          {label && <span className={styles.label}>{label}</span>}
        </>
      ) : (
        <>
          <div className={styles.videoPreview}>
            <div className={styles.previewOverlay}>
              <img src={loadingIcon} className={styles.videoLoader} alt="Видео загружается" />
            </div>
            <GatsbyImage image={image.poster} alt={productTitle} />
          </div>
          <div className={styles.video}>
            <video
              autoPlay
              muted
              loop
              playsInline
              ref={videoRef}
              onCanPlay={handleShowVideoControls}
            >
              <source src={image.localFile?.publicURL} type="video/mp4" />К сожалению, ваш браузер
              не поддерживает видео :c
            </video>
            {isShowControls && (
              <div
                className={cn({ [styles.overlay]: true, [styles.showOverlay]: !isVideoPlaying })}
                onClick={handlePauseVideo}
              >
                <button type="button" className={styles.playButton}>
                  <img src={isVideoPlaying ? pauseIcon : playIcon} alt="video controls" />
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
