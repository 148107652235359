import React from 'react';

import Images from '../../Images';
import styles from './Delivery.module.scss';

export function Delivery() {
  return (
    <>
      <div className={styles.block}>
        <h6 className={styles.header}>Сроки доставки:</h6>
        <ul>
          <li>
            По Москве и Московской области – 1-2 рабочих дня, не включая время на производство.
          </li>
          <li>По России – от 3-х рабочих дней, не включая время на производство.</li>
        </ul>
      </div>
      <div className={styles.block}>
        <h6 className={styles.header}>Курьер</h6>
        <p>
          Доставка осуществляется с 09:00 до 22:00 по будням и с 10:00 до 18:00 по выходным.
          <br />В день доставки вам придет СМС и позвонят за 30-60 минут.
        </p>
      </div>
      <div className={styles.block}>
        <h6 className={styles.header}>Самовывоз из пункта выдачи</h6>
        <p>
          При поступлении заказа в пункт, вы получите СМС-уведомление.
          <br />
          Заказ будет ждать вас в пункте выдачи 14 дней, в постамате - 3 дня.
        </p>
      </div>
      <div className={styles.block}>
        <h6 className={styles.header}>Наша упаковка</h6>
        <div className={styles.gallery}>
          <div className={styles.item}>
            <Images
              src="info/delivery/blue-box-1.jpg"
              alt="Транспортная коробка"
              className={styles.image}
            />
          </div>
          <div className={styles.item}>
            <Images
              src="info/delivery/blue-box-2.jpg"
              alt="Транспортная коробка"
              className={styles.image}
            />
          </div>
          <div className={styles.item}>
            <Images
              src="info/delivery/blue-box-3.jpg"
              alt="Транспортная коробка"
              className={styles.image}
            />
          </div>
          <div className={styles.item}>
            <Images
              src="info/delivery/blue-box-4.jpg"
              alt="Транспортная коробка"
              className={styles.image}
            />
          </div>
        </div>
      </div>
    </>
  );
}
