import React from 'react';
import cn from 'classnames';
import { Link } from 'gatsby';
import type { ButtonArgs } from '@customTypes/button';

import styles from './Button.module.scss';

export function Button({
  onClick,
  title,
  color,
  link,
  isExternal,
  customClassName,
  form,
  disabled,
}: ButtonArgs) {
  if (!link) {
    return (
      // eslint-disable-next-line react/button-has-type
      <button
        className={cn({
          [styles.btn]: true,
          [styles[`button_color_${color}`]]: color,
          ...(customClassName ? { [customClassName]: true } : {}),
          [styles.disabled]: disabled,
        })}
        onClick={onClick}
        form={form}
        disabled={disabled}
      >
        <div className={styles.buttonInner}>
          <span className={styles.buttonTitle}>{title}</span>
        </div>
      </button>
    );
  }

  if (!isExternal) {
    return (
      <Link
        className={cn({
          [styles.btn]: true,
          [styles[`button_color_${color}`]]: color,
        })}
        to={link}
      >
        <div className={styles.buttonInner}>
          <span className={styles.buttonTitle}>{title}</span>
        </div>
      </Link>
    );
  }

  return (
    <a
      className={cn({
        [styles.btn]: true,
        [styles[`button_color_${color}`]]: color,
      })}
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      <div className={styles.buttonInner}>
        <span className={styles.buttonTitle}>{title}</span>
      </div>
    </a>
  );
}
