import type { ReactNode, MutableRefObject} from 'react';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import type { IGatsbyImageData } from 'gatsby-plugin-image';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useMediaQuery } from '@hooks/useMediaQuery';

import styles from './StickyDesktopInfo.module.scss';

interface StickyDesktopInfoArgs {
  children: ReactNode;
  productTitle: string;
  productSubtitle: string;
  gatsbyImage: any;
  targetRef: MutableRefObject<HTMLDivElement | null>;
}
export function StickyDesktopInfo({
  children,
  productTitle,
  productSubtitle,
  gatsbyImage,
  targetRef,
}: StickyDesktopInfoArgs) {
  const [intersectionState, setIntersectionState] = useState('default');
  const wasChecked = useRef(false);

  const intersectionCallback = useCallback((entries: Array<IntersectionObserverEntry>) => {
    const [entry] = entries;
    let nextState = 'default';

    // for avoiding out-animation on first intersection check
    if (wasChecked.current || window.innerHeight / 2 < window.scrollY) {
      nextState = entry.isIntersecting ? 'hidden' : 'visible';
    } else {
      wasChecked.current = true;
    }
    setIntersectionState(nextState);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(intersectionCallback, {
      rootMargin: '80px',
      threshold: 0,
    });
    const refElement = targetRef.current;
    if (refElement) observer.observe(refElement);
    return () => {
      if (refElement) observer.unobserve(refElement);
    };
  }, [targetRef, intersectionCallback]);

  const isDesktop = useMediaQuery('(min-width: 64em)', true);
  
  if (typeof document !== 'undefined') {
    const portalTarget = document.getElementById('product-info-desktop') as HTMLElement;
    const gatsbyImageSrc = getImage(gatsbyImage) as IGatsbyImageData;
    if (portalTarget && isDesktop) {
      return createPortal(
        <div
          className={cn({
            [styles.wrapper]: true,
            [styles.visible]: intersectionState === 'visible',
            [styles.hidden]: intersectionState === 'hidden',
          })}
        >
          <div className={styles.content}>
            <div className={styles.imageAndTextBlock}>
              <GatsbyImage
                alt={productTitle}
                className={styles.image}
                image={gatsbyImageSrc}
              />
              <div>
                <div className={styles.title}>{productTitle}</div>
                <div className={styles.subtitle}>{productSubtitle}</div>
              </div>
            </div>
            <div className={styles.buttonBlock}>{children}</div>
          </div>
        </div>,
        portalTarget
      );
    }
    return null;
  }
  return null;
}
