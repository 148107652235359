import React from 'react';
import cn from 'classnames';

import type { OptionsPickerArgs, OptionItem } from '@customTypes/product';

import styles from './OptionsPicker.module.scss';

export function OptionsPicker({
  options,
  pickerTitle = '',
  onChange = () => null,
  productUrl = '',
  isOptionSelectable = false,
  customClassName = '',
}: OptionsPickerArgs) {
  const handleOptionChange = (option: OptionItem) => {
    onChange(option);
  };
  return (
    <div className={cn({ [styles.variantWrapper]: true, customClassName })}>
      <div className={styles.title}>{pickerTitle}</div>
      <div className={styles.variants}>
        {options.map((option) =>
          isOptionSelectable ? (
            <div
              className={cn({
                [styles.variant]: true,
                [styles.active]: productUrl === option.actionUrl && options.length > 1,
              })}
              role="button"
              onClick={() => handleOptionChange(option)}
              // onKeyDown={null}
              tabIndex={0}
              key={`product-option-${option.id}`}
            >
              {option.value}
            </div>
          ) : (
            <div
              className={cn({
                [styles.option]: true,
              })}
              role="button"
              key={`product-option-${option.id}`}
            >
              {option.icon && (
                <img
                  className={styles.image}
                  src={option.icon?.localFile.publicURL}
                  alt="Иконка варианта"
                />
              )}
              {option.value}
            </div>
          )
        )}
      </div>
    </div>
  );
}
