import React from 'react';
import { Link } from 'gatsby';
import cn from 'classnames';
import { useLocation } from '@reach/router';

import type { ColorPickerArgs } from '@customTypes/product';

import styles from './ColorPicker.module.scss';

export function ColorPicker({ colors }: ColorPickerArgs) {
  const location = useLocation();
  return (
    <div className={styles.colors}>
      {colors.map((color) => (
        <Link
          key={`product-color-${color.title}`}
          to={color.product.url}
          className={cn({
            [styles.color]: true,
            [styles.active]: location.pathname.indexOf(color.product.url) !== -1,
          })}
          title={color.title}
        >
          <div className={styles.innerColor} style={{ backgroundColor: color.color }} />
        </Link>
      ))}
    </div>
  );
}
