import React from 'react';
// TODO: у gatsby, вроде, появился встроенный механизм работы с <head />
// @ts-ignore

interface SchemaOrgProductArgs {
  title: string;
  description: string;
  imageURL?: string | null;
  minPrice: number;
  maxPrice?: number | null;
}

export function SchemaOrgProduct({
  title,
  description,
  imageURL,
  minPrice,
  maxPrice,
}: SchemaOrgProductArgs) {
  return (
    <script type="application/ld+json">
      {JSON.stringify({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '@context': 'http://schema.org/',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '@type': 'Product',
        name: title,
        ...(imageURL ? { image: imageURL } : {}),
        description,
        offers: {
          priceCurrency: 'RUB',
          ...(maxPrice
            ? {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                '@type': 'AggregateOffer',
                lowPrice: minPrice,
                highPrice: maxPrice,
              }
            : {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                '@type': 'Offer',
                price: minPrice,
              }),
        },
      })}
    </script>
  );
}
