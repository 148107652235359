import { useMemo } from 'react';
import { useSyncExternalStore } from 'use-sync-external-store/shim/index';

const IS_SERVER = typeof window === 'undefined';

export function useMediaQuery(query: string, initialState: boolean) {
  const [subscribe, getSnapshot] = useMemo(() => {
    const media = !IS_SERVER ? window.matchMedia(query) : null;

    function subscribe(onStoreChange: any) {
      if (!IS_SERVER) media?.addEventListener('change', onStoreChange);
      return () => {
        if (!IS_SERVER) media?.removeEventListener('change', onStoreChange);
      };
    }

    function getSnapshot() {
      return !IS_SERVER ? media?.matches : initialState;
    }
    return [subscribe, getSnapshot];
  }, [query, initialState]);
  return useSyncExternalStore(subscribe, getSnapshot);
}
