import React from 'react';
import cn from 'classnames';

import arrowDown from '@images/icons/selector-arrow-down.svg';

import styles from './ProductInfoBlock.module.scss';

interface ProductInfoBlockArgs {
  header: string;
  content: React.ReactNode;
  isOpen?: boolean;
}

export function ProductInfoBlock({ header, content, isOpen = false }: ProductInfoBlockArgs) {
  const [isBlockOpen, setIsBlockOpen] = React.useState(isOpen);
  const handleToggleBlock = React.useCallback(() => setIsBlockOpen(!isBlockOpen), [isBlockOpen]);

  return (
    <>
      <div>
        <div
          className={styles.header}
          onClick={handleToggleBlock}
          // onKeyDown={null}
          role="button"
          tabIndex={0}
        >
          <h3>{header}</h3>
          <img
            src={arrowDown}
            alt="selector arrow"
            className={cn({
              [styles.open]: isBlockOpen,
            })}
          />
        </div>
        <div
          className={cn({
            [styles.content]: true,
            [styles.contentOpen]: isBlockOpen,
          })}
        >
          {content}
        </div>
      </div>
      <div className={styles.line} />
    </>
  );
}
