import React from 'react';
import cn from 'classnames';

import type { BuyButtonArgs } from '@customTypes/product';
import { currency } from '@utils/numbers';
import { RubleSign } from '@components/Blocks/RubleSign';

import styles from './BuyButton.module.scss';

const CONSTRUCTOR_URL = process.env.GATSBY_CONSTRUCTOR_URL;

export function BuyButton({
  optionSalePrice,
  realPrice,
  isProductUnavailable,
  isPreorder,
  onClick,
  buttonTitle,
  productUrl,
  inPlate = false,
}: BuyButtonArgs) {
  const buttonText = React.useMemo(
    () => (isPreorder ? 'Оформить предзаказ' : buttonTitle),
    [isPreorder, buttonTitle]
  );

  const handleClick = React.useCallback(() => {
    if (!isProductUnavailable) {
      onClick();
    }
  }, [isProductUnavailable, onClick]);

  return isPreorder ? (
    <button
      type="button"
      onClick={handleClick}
      className={cn({
        [styles.actionBtn]: true,
        [styles.preorder]: isPreorder,
        [styles.actionBtnDisabled]: isProductUnavailable,
        [styles.inPlate]: inPlate,
      })}
    >
      <span>{buttonText}</span>
      {isProductUnavailable ? (
        <div className={styles.actionPrice}>Нет в наличии</div>
      ) : (
        <div className={styles.actionPrice}>
          {optionSalePrice ? (
            <>
              {currency(optionSalePrice)}&nbsp;
              <RubleSign />
              <span className={styles.realPrice}>
                {currency(realPrice)}&nbsp;
                <RubleSign />
              </span>
            </>
          ) : (
            <>
              {currency(realPrice)}&nbsp;
              <RubleSign />
            </>
          )}
        </div>
      )}
    </button>
  ) : (
    <a
      type="button"
      onClick={handleClick}
      href={`${CONSTRUCTOR_URL}${productUrl}`}
      target="_blank"
      rel="noreferrer"
      className={cn({
        [styles.actionBtn]: true,
        [styles.preorder]: isPreorder,
        [styles.actionBtnDisabled]: isProductUnavailable,
        [styles.inPlate]: inPlate,
      })}
    >
      <span className={styles.text}>{buttonText}</span>
      {isProductUnavailable ? (
        <div className={styles.actionPrice}>Нет в наличии</div>
      ) : (
        <div className={styles.actionPrice}>
          {optionSalePrice ? (
            <>
              {currency(optionSalePrice)}&nbsp;
              <RubleSign />
              <span className={styles.realPrice}>
                {currency(realPrice)}&nbsp;
                <RubleSign />
              </span>
            </>
          ) : (
            <>
              {currency(realPrice)}&nbsp;
              <RubleSign />
            </>
          )}
        </div>
      )}
    </a>
  );
}
